import { Modal } from "react-responsive-modal"
import "react-responsive-modal/styles.css"
import * as React from "react"

const PitchEntry = ({ pitch }) => {
    const [open, setOpen] = React.useState(false)

    return (
        <div>
            <a href="#" onClick={(event) => {
                event.preventDefault()
                setOpen(true)
            }}>
                <div className="panel panel-default">
                    <div className="panel-body text-black">
                        <h3 className="title">{pitch.title}</h3>
                        <small className="text-muted">Time</small>
                        <p className="authors">{pitch.time}</p>
                        <small className="text-muted">Authors</small>
                        <p className="authors">{pitch.authors.join(", ")}</p>
                        <small className="text-muted">Abstract</small>
                        <p className="abstract">{pitch.abstract}</p>
                    </div>
                </div>
            </a>

            <Modal open={open} onClose={() => setOpen(false)} center>
                <h2 className="modal-title">{pitch.title}</h2>
                <small className="text-muted">Time</small>
                <p>{pitch.time}</p>
                <small className="text-muted">Authors</small>
                <p>{pitch.authors.join(", ")}</p>
                <small className="text-muted">Abstract</small>
                <p>{pitch.abstract}</p>
            </Modal>
        </div>
    )
}

export default PitchEntry
